<template>
  <div>
    <a-row style="text-align: right; margin-bottom: 10px">
      <span style="color:green">(不包含已取消订单)金额：{{ totalFee | formatCurrency }}元</span>
    </a-row>

    <a-table
      size="middle"
      :columns="tableColumns"
      :data-source="tableData"
      :pagination="false"
      :expand-icon="expandIcon"
      row-key="id"
      :scroll="{x:1000,y:350}"
    >
      <div slot="payment_method" slot-scope="payment_method, record">
        <span>{{ record.payment_status ? payment_method: '-' }}</span>
      </div>
      <div slot="voucher_fee" slot-scope="voucher_fee, record">
        <span>{{ record.payment_status ? voucher_fee: '-' }}</span>
      </div>
      <div slot="payment_fee" slot-scope="payment_fee, record">
        <span>{{ record.payment_status ? payment_fee: '-' }}</span>
      </div>
      <div slot="payment_at" slot-scope="payment_at, record">
        <span>{{ record.payment_status ? payment_at: '-' }}</span>
      </div>
      <a-table
        slot="expandedRowRender"
        slot-scope="record"
        :columns="childrenColumns"
        :data-source="record.service_order_other_product_items"
        :pagination="false"
        row-key="id"
      >
        <div slot="effective" slot-scope="effective">
          <span>{{ effective ? '有效': '失效' }}</span>
        </div>
      </a-table>
    </a-table>
  </div>
</template>

<script>
import { findServiceOrderOtherItems } from '@/api/order'

export default {
  name: 'ProjectSummary',
  components: {
  },
  data() {
    return {
      data: {},
      tableData: [],
      totalFee: 0
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          title: '项目编号',
          dataIndex: 'order_no',
          width: 150
        },
        {
          title: '项目来源',
          dataIndex: 'source',
          width: 150
        },
        {
          title: '物品件数',
          dataIndex: 'sub_count',
          width: 150
        },
        {
          title: '项目金额(元)',
          dataIndex: 'sub_fee_display',
          width: 150
        },
        {
          title: '支付方式',
          dataIndex: 'payment_way',
          width: 150
        },
        {
          title: '状态',
          dataIndex: 'payment_status_display',
          width: 150
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          width: 200
        },
        {
          title: '支付单号',
          dataIndex: 'payment_no',
          width: 150
        },
        {
          title: '支付途径',
          dataIndex: 'payment_method',
          width: 200,
          scopedSlots: { customRender: 'payment_method' }
        },
        {
          title: '优惠金额(元)',
          dataIndex: 'voucher_fee',
          width: 200,
          scopedSlots: { customRender: 'voucher_fee' }
        },
        {
          title: '实际支付金额(元)',
          dataIndex: 'payment_fee',
          width: 200,
          scopedSlots: { customRender: 'payment_fee' }
        },
        {
          title: '支付时间',
          dataIndex: 'payment_at',
          width: 250,
          scopedSlots: { customRender: 'payment_at' }
        }
      ]
    },
    childrenColumns() {
      return [
        {
          title: '序号',
          dataIndex: 'id',
          width: 100
        },
        {
          title: '品名',
          dataIndex: 'product_name',
          width: 100
        },
        {
          title: '规格',
          dataIndex: 'product_specification_name',
          width: 100
        },
        {
          title: '价格',
          dataIndex: 'price',
          width: 100
        },
        {
          title: '数量',
          dataIndex: 'sub_count',
          width: 100
        },
        {
          title: '金额(元)',
          dataIndex: 'sub_fee_display',
          width: 100
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    expandIcon({ expanded, expandable, record, onExpand }) {
      if (!expandable) return null
      return (
        <a onClick={e => onExpand(record, e)}>
          {expanded ? <a-icon type='minus-square' /> : <a-icon type='plus-square' />}
        </a>
      )
    },

    fetchData() {
      findServiceOrderOtherItems(this.id).then((res) => {
        this.tableData = res.data.data
        this.totalFee = res.data.total_fee
      })
    }
  }
}
</script>

