<template>
  <div>
    <a-tabs
      type="card"
      v-decorator="['tags', {
      }]"
      @change="handleChange"
    >
      <a-tab-pane key="basic" tab="项目汇总">
        <project-summary
          :id="id"
          ref="child"
        />
      </a-tab-pane>
      <a-tab-pane key="family" tab="订购记录">
        <order-record
          :id="id"
          ref="child"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import ProjectSummary from '@/views/orders/system/other_projects/ProjectSummary'
import OrderRecord from '@/views/orders/system/other_projects/OrderRecord'

export default {
  name: 'OrderRecordInfo',
  components: {
    ProjectSummary,
    OrderRecord
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleChange(e) {
      return {
        tab: e.tab,
        index: e.index
      }
    },
    fetchData() {
      this.$refs.child.fetchData()
    }
  }
}
</script>

<style lang="less" scoped>
.custom-blue {
  cursor: pointer;
}
</style>
